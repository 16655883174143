import React, { useState, useEffect } from 'react';
import { themeChange } from 'theme-change';
import logo from './logo.png'; // Adjust the path if your logo is in a different directory

const API_BASE_URL = '/api';

function SearchByAyah() {
  const [suras, setSuras] = useState([]);
  const [ayahs, setAyahs] = useState([]);
  const [madhabs, setMadhabs] = useState([]);
  const [tafsirs, setTafsirs] = useState([]);
  const [selectedSura, setSelectedSura] = useState('');
  const [selectedAyah, setSelectedAyah] = useState('');
  const [selectedMadhab, setSelectedMadhab] = useState('');
  const [selectedTafsir, setSelectedTafsir] = useState('');
  const [ayah, setAyah] = useState(null);
  const [tafsirTexts, setTafsirTexts] = useState([]);
  const [selectedSuraName, setSelectedSuraName] = useState('');
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [noTafsirFound, setNoTafsirFound] = useState(false);
  const [tafsirDescription, setTafsirDescription] = useState('');

  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  useEffect(() => {
    themeChange(false);

    const savedTheme = localStorage.getItem('theme') || 'light';
    document.documentElement.setAttribute('data-theme', savedTheme);

    const handleThemeChange = (event) => {
      const selectedTheme = event.target.value;
      document.documentElement.setAttribute('data-theme', selectedTheme);
      localStorage.setItem('theme', selectedTheme);
    };

    const themeSelector = document.querySelector('[data-choose-theme]');
    if (themeSelector) {
      themeSelector.value = savedTheme;
      themeSelector.addEventListener('change', handleThemeChange);
    }

    return () => {
      if (themeSelector) {
        themeSelector.removeEventListener('change', handleThemeChange);
      }
    };
  }, []);

  useEffect(() => {
    if (isOnline) {
      fetch(`${API_BASE_URL}/suras`)
        .then(response => response.json())
        .then(data => setSuras(data));

      fetch(`${API_BASE_URL}/madhabs`)
        .then(response => response.json())
        .then(async (data) => {
          if (data && Array.isArray(data)) {
            const madhabsWithCounts = await Promise.all(data.map(async (madhab) => {
              try {
                const response = await fetch(`${API_BASE_URL}/tafsirs/count?madhab_number=${madhab.madhab_number}`);
                const count = await response.json();
                return { ...madhab, count };
              } catch (error) {
                console.error('Error fetching tafsir count for madhab:', error);
                return { ...madhab, count: 0 };
              }
            }));
            setMadhabs(madhabsWithCounts);
          }
        })
        .catch(error => console.error('Error fetching madhabs:', error));
    }
  }, [isOnline]);

  useEffect(() => {
    if (selectedSura) {
      fetch(`${API_BASE_URL}/ayahs/${selectedSura}`)
        .then(response => response.json())
        .then(data => setAyahs(data));
    }
  }, [selectedSura]);

  useEffect(() => {
    if (selectedMadhab) {
      fetch(`${API_BASE_URL}/tafsirs?madhab_numbers=${selectedMadhab}`)
        .then(response => response.json())
        .then(data => setTafsirs(data));
    }
  }, [selectedMadhab]);

  const handleFetchAyahAndTafsir = () => {
    if (selectedSura && selectedAyah && selectedTafsir) {
      const selectedSuraObj = suras.find(sura => sura.sura_number === parseInt(selectedSura));
      setSelectedSuraName(selectedSuraObj ? `${selectedSuraObj.sura_number} - ${selectedSuraObj.name}` : '');

      fetch(`${API_BASE_URL}/ayah/${selectedSura}/${selectedAyah}`)
        .then(response => response.json())
        .then(data => setAyah(data));

      const selectedTafsirObj = tafsirs.find(tafsir => tafsir.tafsir_number === parseInt(selectedTafsir));
      setTafsirDescription(selectedTafsirObj ? selectedTafsirObj.description : '');

      fetch(`${API_BASE_URL}/tafsir_texts/${selectedSura}/${selectedAyah}?tafsir_numbers=${selectedTafsir}`)
        .then(response => response.json())
        .then(data => {
          setTafsirTexts(data);
          setNoTafsirFound(data.length === 0);
        });
    }
  };

  return (
    <div className="container mx-auto p-4 bg-base-100 text-base-content" dir="rtl">
      <header className="mb-4 flex flex-col items-center">
        <img src={logo} alt="موسوعة التفاسير Logo" className="h-16 mb-4" />
        <h2 className="text-xl font-bold mt-4 mb-2 text-center">أضخم موسوعة لتفسير القرآن الكريم، تحوي 130 تفسيرا من مختلف المذاهب الإسلامية.</h2>
        <div className="flex items-center mb-4 mt-2">
          <select className="select select-bordered font-bold" data-choose-theme defaultValue={localStorage.getItem('theme') || 'light'}>
            <option value="light">اللون 1</option>
            <option value="retro">اللون 2</option>
            <option value="cupcake">اللون 3</option>
            <option value="garden">اللون 4</option>
            <option value="lemonade">اللون 5</option>
            <option value="winter">اللون 6</option>
            <option value="emerald">اللون 7</option>
            <option value="corporate">اللون 8</option>
            <option value="pastel">اللون 9</option>
            <option value="fantasy">اللون 10</option>
            <option value="autumn">اللون 11</option>
            <option value="acid">اللون 12</option>
            <option value="cmyk">اللون 13</option>
            <option value="nord">اللون 14</option>
            <option value="valentine">اللون 15</option>
            {/* Add more themes as needed */}
          </select>
        </div>
      </header>

      {!isOnline && (
        <div className="alert alert-warning mb-4">
          <span>⚠️</span>
          <span>الاتصال بالإنترنت مطلوب</span>
        </div>
      )}

      <div className="mb-4">
        <select className="select select-bordered w-full font-bold" onChange={e => setSelectedSura(e.target.value)} value={selectedSura}>
          <option value="" className="font-bold">اختر سورة</option>
          {suras.map(sura => (
            <option key={sura.sura_number} value={sura.sura_number} className="font-bold">
              {sura.sura_number}-{sura.name}
            </option>
          ))}
        </select>
      </div>

      <div className="mb-4">
        <select className="select select-bordered w-full font-bold" onChange={e => setSelectedAyah(e.target.value)} value={selectedAyah}>
          <option value="" className="font-bold">اختر آية</option>
          {ayahs.map(ayah => (
            <option key={ayah.ayah_number} value={ayah.ayah_number} className="font-bold">
              {ayah.ayah_number} - {ayah.text.split(' ').slice(0, 10).join(' ')}...
            </option>
          ))}
        </select>
      </div>
      <div className="mb-4">
        <select className="select select-bordered w-full font-bold" onChange={e => setSelectedMadhab(e.target.value)} value={selectedMadhab}>
          <option value="" className="font-bold">اختر مذهب</option>
          {madhabs.map(madhab => (
            <option key={madhab.madhab_number} value={madhab.madhab_number} className="font-bold">
              {madhab.name} ({madhab.count} تفسير)
            </option>
          ))}
        </select>
      </div>
      <div className="mb-4">
        <select className="select select-bordered w-full font-bold" onChange={e => setSelectedTafsir(e.target.value)} value={selectedTafsir}>
          <option value="" className="font-bold">اختر تفسير</option>
          {tafsirs.map(tafsir => (
            <option key={tafsir.tafsir_number} value={tafsir.tafsir_number} className="font-bold">
              {tafsir.name} ({tafsir.author_death} هجري)
            </option>
          ))}
        </select>
      </div>
      <div className="mb-4">
        <button className="btn btn-primary w-full" onClick={handleFetchAyahAndTafsir} disabled={!isOnline}>
          ابحث عن التفسير
        </button>
      </div>
      {tafsirDescription && (
        <div className="mb-4">
          <h2 className="text-xl font-bold mb-2">عن الكتاب</h2>
          <div dangerouslySetInnerHTML={{ __html: tafsirDescription }} />
        </div>
      )}
      {ayah && (
        <div className="mb-4">
          <h2 className="text-xl font-bold mb-2">السورة</h2>
          <p>{selectedSuraName}</p>
          <h2 className="text-xl font-bold mb-2 mt-4">الآية</h2>
          <p>{ayah.ayah_number} - {ayah.text_with_tashkeel}</p>
        </div>
      )}
      <div className="mb-4">
        <h2 className="text-xl font-bold mb-2">التفسير</h2>
        {noTafsirFound ? (
          <p>لا يوجد تفسير لهذه الآية</p>
        ) : (
          tafsirTexts.map(tafsirText => (
            <div key={tafsirText.id} className="mb-2">
              <h3 className="font-semibold">{tafsirText.book_name}</h3>
              <div dangerouslySetInnerHTML={{ __html: tafsirText.text }} />
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default SearchByAyah;
