import React from 'react';

const Contact = () => {
  return (
    <div className="container mx-auto p-4 bg-base-100 text-base-content" dir="rtl">
      <header className="mb-4">
        <h1 className="text-3xl font-bold">اتصل بنا</h1>
      </header>
      <section className="mb-4">
        <p>
          يمكنكم التواصل مع الدكتور عبد الكريم منصور عبر هذا البريد الإلكتروني karim1104@gmail.com
        </p>
      </section>
    </div>
  );
};

export default Contact;
