import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto p-4 bg-base-100 text-base-content" dir="rtl">
      <header className="mb-4">
        <h1 className="text-3xl font-bold">سياسة الخصوصية</h1>
      </header>
      <section className="mb-4">
        <p>
          نحن في موقع Tafasir.org، الذي تديره Tadawul Academy، نحترم خصوصيتك ونلتزم بحمايتها. لا نقوم بجمع أي معلومات شخصية أو حساسة عن المستخدمين. نحن لا نتتبع أي شيء أو أي شخص.
        </p>
      </section>
      <section className="mb-4">
        <h2 className="text-2xl font-bold">المعلومات التي نجمعها</h2>
        <p>
          نحن لا نجمع أي معلومات شخصية أو حساسة عن المستخدمين. الاختيار الوحيد الذي نحفظه هو الخيار من قائمة تغيير الثيم.
        </p>
      </section>
      <section className="mb-4">
        <h2 className="text-2xl font-bold">استخدام المعلومات</h2>
        <p>
          نحن لا نستخدم أي معلومات شخصية أو حساسة عن المستخدمين لأننا لا نقوم بجمعها.
        </p>
      </section>
      <section className="mb-4">
        <h2 className="text-2xl font-bold">مشاركة المعلومات</h2>
        <p>
          نحن لا نشارك أي معلومات شخصية أو حساسة عن المستخدمين مع أي طرف ثالث.
        </p>
      </section>
      <section className="mb-4">
        <h2 className="text-2xl font-bold">حقوقك</h2>
        <p>
          نظرًا لأننا لا نجمع أي معلومات شخصية أو حساسة عن المستخدمين، لا يوجد لدينا معلومات لتصحيحها أو حذفها. إذا كانت لديك أي استفسارات، يرجى الاتصال بنا عبر البريد الإلكتروني: karim1104@gmail.com.
        </p>
      </section>
      <section className="mb-4">
        <h2 className="text-2xl font-bold">التعديلات على سياسة الخصوصية</h2>
        <p>
          قد نقوم بتحديث سياسة الخصوصية هذه من وقت لآخر. سنقوم بإخطارك بأي تغييرات عن طريق نشر السياسة المحدثة على هذه الصفحة. ننصحك بمراجعة هذه الصفحة بانتظام للبقاء على اطلاع بأحدث المعلومات حول ممارسات الخصوصية لدينا.
        </p>
      </section>
      <section className="mb-4">
        <h2 className="text-2xl font-bold">الاتصال بنا</h2>
        <p>
          إذا كانت لديك أي أسئلة حول سياسة الخصوصية هذه، يرجى الاتصال بنا عبر البريد الإلكتروني على karim1104@gmail.com.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
